import { TbMinus, TbPlus } from "react-icons/tb";
import { useCartStore } from "../../../Store";

export const ProductsSidebar = ({ data = [] }) => {

    const removeProduct = useCartStore(state => state.removeProduct);
    const increaseQuantity = useCartStore(state => state.increaseQuantity);
    const decreaseQuantity = useCartStore(state => state.decreaseQuantity);

    // Calcular el precio total usando reduce
    const totalPrice = data.reduce((acc, product) => {
        // Asegúrate de convertir precio y cantidad en números válidos
        const price = parseFloat(String(product.price).replace(',', '.')) || 0;
        const qty = parseFloat(String(product.qty).replace(',', '.')) || 0;
        return acc + price * qty;
    }, 0).toFixed(2); // Redondea a dos decimales

    return (
        <div className="max-h-[70vh] overflow-y-auto pt-2">
            {
                data.map((e) =>
                    <div className="flex mb-6" key={e.id}>
                        <div className="bg-custom-gray w-[80px] h-[60px] p-[5px] rounded-md relative flex justify-center items-center">
                            <span
                                className="absolute text-xs h-5 rounded-full px-1.5 font-bold -top-2 -right-2 bg-green-500 text-white cursor-pointer"
                                onClick={() => removeProduct(e.id)}
                            >
                                x
                            </span>
                            <img src={e.imgs} alt="" className="object-cover w-[70pxpx] h-[50px]" />
                        </div>
                        <div className="w-full ms-4">
                            <span className='font-bold text-lg'>
                                {e.name}
                            </span>
                            <br />
                            <div className="flex gap-3 mt-2">
                                <div className="flex items-center">
                                    <div 
                                        className="w-7 h-7 border flex justify-center items-center rounded-lg"
                                        onClick={() => decreaseQuantity(e.id)}
                                    >
                                        <TbMinus className="text-xs cursor-pointer" />
                                    </div>
                                    <div className="w-7 h-7 flex justify-center items-center">
                                        {e.qty}
                                    </div>
                                    <div 
                                        className="w-7 h-7 border flex justify-center items-center rounded-lg"
                                        onClick={() => increaseQuantity(e.id)}
                                    >
                                        <TbPlus className="text-xs cursor-pointer" />
                                    </div>
                                </div>
                                <span>
                                    ${ (parseFloat(String(e.price).replace(',', '.')) * e.qty).toFixed(2) }
                                </span> {/* Esto calculará el total por producto */}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};
