import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useCartStore = create(
    persist(
        (set, get) => ({
            cart: [],
            smsCompra: "",

            getTotalItem: () => {
                const { cart } = get();
                return cart.length;
            },

            getTotalPrice: () => {
                const { cart } = get();
                return cart.reduce((total, item) => total + (item.price * item.qty), 0);
            },

            addProductToCart: (product) => {
                const { cart } = get();
                const productInCart = cart.find((item) => item.id === product.id);

                if (!productInCart) {
                    set({ cart: [...cart, { ...product, qty: 1 }] });
                }
            },

            increaseQuantity: (id) => {
                const { cart } = get();
                const updatedCart = cart.map(item => {
                    if (item.id === id) {
                        return { ...item, qty: item.qty + 1 };
                    }
                    return item;
                });
                set({ cart: updatedCart });
            },

            decreaseQuantity: (id) => {
                const { cart } = get();
                const updatedCart = cart.map(item => {
                    if (item.id === id && item.qty > 1) {
                        return { ...item, qty: item.qty - 1 };
                    }
                    return item;
                });
                set({ cart: updatedCart });
            },

            removeProduct: (id) => {
                const { cart } = get();
                const updatedCartProducts = cart.filter((item) => item.id !== id);
                set({ cart: updatedCartProducts });
            },

            removeAllProduct: () => {
                set({ cart: [] });
            },

            getSmsCompra: (sms) => {
                set({ smsCompra: sms });
            }
        }),
        {
            name: 'shopping-cart'
        }
    )
);

